import React from 'react'
import { Link } from 'gatsby'
import styles from './index.module.css'
import logoImg from '../../assets/logo.png'
import linkedIn from '../../assets/lin.png'
import mailImg from '../../assets/email.png'

export default function Footer() {
  const currYear = new Date().getFullYear()
  return (
    <div className={styles.root}>
      <div className={styles.content1}>
        <div className={styles.infoCtr}>
          <Link to='/'>
            <img src={logoImg} alt="Torquedev" className={styles.logo} />
          </Link>
          <a href='mailto:sales@torquedev.xyz'>
            <div className={styles.mail}>
              <img src={mailImg} />
              sales@torquedev.xyz
            </div>
          </a>
        </div>
        <div className={styles.links}>
          <div>
            <Link to='/'>Home</Link>
            <div className={styles.linkGroup}>
              <Link to='/projects/'>Projects</Link>
              <div className={styles.sublinks}>
                <Link to='/projects/#lazylocal'>Lazylocal</Link>
                <Link to='/projects/#venuemob'>Venuemob Manager</Link>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.linkGroup}>
              <Link to='/services/'>Services</Link>
              <div className={styles.sublinks}>
                <Link to='/services/#architecture'>Software Architecture</Link>
                <Link to='/services/#full-stack'>Full-Stack Development</Link>
                <Link to='/services/#devops'>DevOps</Link>
                <Link to='/services/#troubleshooting'>Code Troubleshooting</Link>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.linkGroup}>
              <Link to='/contact/'>
                Contact
              </Link>
            </div>
            <div className={styles.linkGroup}>
              <Link to='/privacy/'>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sep}></div>
      <div className={styles.copyCtr}>
        <div className={styles.grow}>
          © {currYear} torquedev. All Rights Reserved.
          <br />
          Photos/Vectors from <a href="https://pixabay.com">Pixabay</a> and <a href="https://vecteezy.com">Vecteezy</a>.
        </div>
        <div className={styles.social}>
          <Link to='https://linkedin.com/company/torquedev'>
            <img src={linkedIn} alt='LinkedIn' />
          </Link>
        </div>
      </div>
    </div>
  );
}
