import { useState, useEffect } from 'react';

export function throttle(listener, delay) {
  let currentTimeout = null;
  let currVal = null;

  return (evVal) => {
    currVal = evVal;
    if (!currentTimeout) {
      currentTimeout = setTimeout(() => {
        currentTimeout = null;
        listener(currVal);
      }, delay);
    }
  };
}

export function useScrollEv(delay, reducer) {
  const [state, setState] = useState(null);
  function callback() {
    let newVal;
    if (!reducer) {
      newVal = window.scrollY;
    } else {
      newVal = reducer(window.scrollY);
    }
    if (newVal !== state) {
      setState(newVal);
    }
  }
  useEffect(() => {
    const listener = throttle(callback, delay);
    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);
  return state;
}

export function useLoaded() {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    let checkInterval = setInterval(() => {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          setLoaded(true);
          clearInterval(checkInterval);
        }, 500);
      }
    }, 250);
  }, []);

  return isLoaded;
}

export function c(cssClasses) {
  return cssClasses.filter((v) => !!v).join(' ')
}
