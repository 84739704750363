import React from 'react';
import { Link } from 'gatsby';
import styles from './index.module.css';
import logo from '../../assets/logo.png';

export default function Header() {
  return (
    <>
    <div className={styles.topbar}>
      <Link to="/">
        <img src={logo} alt="Torquelabs" />
      </Link>

      <div className={styles.grow}></div>

      <div className={styles.topbarLinks}>
        <Link to="/services/">Services</Link>
        <Link to="/projects/">Projects</Link>
        <Link to="/contact/">Contact</Link>
      </div>
    </div>
    <div className={styles.topbarSpacer}></div>
    </>
  );
}
