import React, { useEffect } from 'react';
import styles from './index.module.css';
import gearImg from '../../assets/gear.png';
import { useLoaded } from '../../util';

export default function Preloader() {
  const isLoaded = useLoaded();

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = isLoaded ? 'unset' : 'hidden';
  });

  if (isLoaded) {
    return null;
  }

  return (
    <div className={styles.root}>
      <img className={styles.gear} src={gearImg} alt='Loading' />
    </div>
  );
}
