import React from 'react'
import styles from './index.module.css'
import Header from '../Header'
import Footer from '../Footer'
import Preloader from '../Preloader'

export default function Layout({ children }) {
  return (
    <>
      <Preloader />
      <Header />
      <div className={styles.content}>
        {children}
      </div>
      <Footer />
    </>
  );
}
